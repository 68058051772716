.heroSection {
  height: 100vh;
  width: 100vw;
  position: relative;
  /* background-image: url("../images/Rema.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.heroOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(48, 48, 46, 0.8) 0%, /* Adjust opacity here (0.8 in this case) */ rgba(77, 68, 52, 0.8) 18.3%, /* Adjust opacity here */ rgba(77, 68, 52, 0.8) 36.6%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 68.3%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 100% /* Adjust opacity here */);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 10;
}
.heroOverlay header {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Mooli", sans-serif;
  color: #fff;
}

.heroOverlay header button:nth-child(1) {
  background: #e4b848;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
  border: none;
  border-radius: 4px;
  min-width: 130px;
  justify-content: center;
  font-family: "Mooli", sans-serif;
}
.heroOverlay header h1 {
  font-size: 45px;
  font-family: "Mooli", sans-serif;
  /* font-weight: b; */
}
.heroOverlay header h2 {
  font-size: 28px;
}
.heroOverlay header div {
  gap: 40px;
  justify-content: center;
}
.heroOverlay header button:nth-child(2) {
  background: inherit;
  height: 48px;
  padding: 0 20px;
  font-size: 16px;
  color: #fff;
  border: 1px solid #e4b848;
  border-radius: 4px;
  min-width: 170px;
  justify-content: center;
  gap: 10px;
  font-family: "Mooli", sans-serif;
}
.heroOverlay .nextScroll {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  color: #fff;
  cursor: pointer;
}

.bestSection {
  background: #000;
  padding: 40px 20px;
  display: grid;
  grid-template-columns: 100%;
  font-family: "Mooli", sans-serif;
  align-items: center;
}

.bestSection h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
}

.bestSection p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 24px;
  max-width: 800px;
}
.bestSection ul {
  /* justify-content: center; */
  gap: 20px;
  overflow: scroll;
  overflow-y: hidden;
  margin: 40px 0 0 0;
}
.bestSection ul li {
  background: inherit;
  border: 1px solid #e4b848;
  padding: 16px;
  min-width: 300px;
  color: #e4b848;
  gap: 20px;
  justify-content: center;
  border-radius: 4px;
}

.bestSection aside:nth-child(2) div {
  border-radius: 10px;
}
.bestSection aside:nth-child(2) div img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 0;
}
.bestSection aside:nth-child(2) div:nth-child(1) {
  margin-top: 25px;
}
.singlesSection {
  background: #171005;
  padding: 40px 20px;
  font-family: "Mooli", sans-serif;
}
.singlesSection header h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
}
.singlesSection header p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  max-width: 800px;
}
.singlesSection header button {
  background: inherit;
  border: none;
  color: #e4b848;
  font-size: 14px;
  align-self: flex-end;
  gap: 10px;
  font-family: "Mooli", sans-serif;
}

.imageRowView {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  padding: 100px 0;
  overflow: scroll;
}
.imageRowView div {
  position: relative;
}
.imageRowView div ul {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 10px;
  background: rgba(48, 48, 46, 0.3);
}
.imageRowView div ul button {
  border: 1px solid #e4b848;
  border-radius: 6px;
  padding: 5px 16px;
  background: rgba(48, 48, 46, 0.6);
  color: #e4b848;
  font-family: "Mooli", sans-serif;
}

/* Create four equal columns that sits next to each other */

.imageRowView img {
  width: 100%;
  /* height: 300px; */
  object-fit: cover;
}

.artistesSection {
  background: #000;
  padding: 40px 20px;
  font-family: "Mooli", sans-serif;
}

.artistesSection header button {
  background: inherit;
  border: none;
  color: #e4b848;
  font-size: 14px;
  align-self: flex-end;
  gap: 10px;
  font-family: "Mooli", sans-serif;
}
.artistesSection header h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
}
.artistesSection header p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  max-width: 800px;
}
.artistesCarouselContainer {
  padding: 40px 0;
}
.artistesCarouselContainer img {
  height: 400px;
  width: 100%;
}
.slide {
  transform: scale(0.7);
  transition: transform 300ms;
  opacity: 0.5;
}

.slideWrapper {
  display: flex;
  justify-content: center;
}

.prevArrow {
  position: absolute;
  top: 40%;
  left: 0px;
  z-index: 100;
  cursor: pointer;
  color: #e4b848;
  font-size: 2rem;
}

.nextArrow {
  position: absolute;
  top: 40%;
  right: 0px;
  z-index: 100;
  cursor: pointer;
  font-size: 2rem;
  color: #e4b848;
}
.brandsSection {
  background: rgba(23, 16, 5);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  padding: 40px 20px;
  font-family: "Mooli", sans-serif;
}
.brandsSection header h2 {
  color: #fff;
  font-size: 35px;
  font-weight: 700;
}
.brandsSection header p {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  max-width: 800px;
}
.brandsSection ul {
  /* justify-content: center; */
  gap: 60px 20px;
  flex-wrap: wrap;
  margin: 40px 0 0 0;
  justify-content: center;
}
.brandsSection ul li {
  height: 100%;
  width: 100px;
}
.brandsSection ul li img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .heroSection {
    height: 100vh;
    width: 100vw;
    position: relative;
    /* background-image: url("../images/Rema.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
  }
  .heroOverlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(48, 48, 46, 0.8) 0%, /* Adjust opacity here (0.8 in this case) */ rgba(77, 68, 52, 0.8) 18.3%, /* Adjust opacity here */ rgba(77, 68, 52, 0.8) 36.6%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 68.3%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 100% /* Adjust opacity here */);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .heroOverlay header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-family: "Mooli", sans-serif;
    color: #fff;
  }

  .heroOverlay header button:nth-child(1) {
    background: #e4b848;
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    min-width: 130px;
    justify-content: center;
    font-family: "Mooli", sans-serif;
  }
  .heroOverlay header h1 {
    font-size: 60px;
    font-family: "Mooli", sans-serif;
    /* font-weight: b; */
  }
  .heroOverlay header h2 {
    font-size: 32px;
  }
  .heroOverlay header div {
    gap: 40px;
    justify-content: center;
  }
  .heroOverlay header button:nth-child(2) {
    background: inherit;
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    color: #fff;
    border: 1px solid #e4b848;
    border-radius: 4px;
    min-width: 170px;
    justify-content: center;
    gap: 10px;
    font-family: "Mooli", sans-serif;
  }

  .bestSection {
    background: #000;
    padding: 100px 80px;
    display: grid;
    grid-template-columns: 70% 30%;
    font-family: "Mooli", sans-serif;
    /* height: 100vh; */
    align-items: center;
  }

  .bestSection h2 {
    color: #fff;
    font-size: 55px;
    font-weight: 700;
  }

  .bestSection p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 24px;
    max-width: 800px;
  }
  .bestSection ul {
    /* justify-content: center; */
    gap: 20px;
    flex-wrap: wrap;
    margin: 40px 0 0 0;
  }
  .bestSection ul li {
    background: inherit;
    border: 1px solid #e4b848;
    padding: 16px;
    min-width: 300px;
    color: #e4b848;
    gap: 20px;
    justify-content: center;
    border-radius: 4px;
  }

  .bestSection aside:nth-child(2) div {
    border-radius: 10px;
  }
  .bestSection aside:nth-child(2) div img {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin: 10px 0;
  }
  .bestSection aside:nth-child(2) div:nth-child(1) {
    margin-top: 25px;
  }
  .singlesSection {
    background: #171005;
    padding: 100px 80px;
    font-family: "Mooli", sans-serif;
  }
  .singlesSection header h2 {
    color: #fff;
    font-size: 55px;
    font-weight: 700;
  }
  .singlesSection header p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    max-width: 800px;
  }
  .singlesSection header button {
    background: inherit;
    border: none;
    color: #e4b848;
    font-size: 14px;
    align-self: flex-end;
    gap: 10px;
    font-family: "Mooli", sans-serif;
  }

  .imageRowView {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    padding: 100px 0;
  }

  /* Create four equal columns that sits next to each other */

  .imageRowView img {
    width: 100%;
    object-fit: cover;
  }

  .artistesSection {
    background: #000;
    padding: 100px 80px;
    font-family: "Mooli", sans-serif;
  }

  .artistesSection header button {
    background: inherit;
    border: none;
    color: #e4b848;
    font-size: 14px;
    align-self: flex-end;
    gap: 10px;
    font-family: "Mooli", sans-serif;
  }
  .artistesSection header h2 {
    color: #fff;
    font-size: 55px;
    font-weight: 700;
  }
  .artistesSection header p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    max-width: 800px;
  }
  .artistesCarouselContainer {
    padding: 100px 0;
  }

  .brandsSection {
    background: rgba(23, 16, 5);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding: 100px 80px;
    font-family: "Mooli", sans-serif;
  }
  .brandsSection header h2 {
    color: #fff;
    font-size: 55px;
    font-weight: 700;
  }
  .brandsSection header p {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-top: 16px;
    max-width: 800px;
  }
  .brandsSection ul {
    /* justify-content: center; */
    gap: 60px 20px;
    flex-wrap: wrap;
    margin: 40px 0 0 0;
    justify-content: center;
  }
  .brandsSection ul li {
    height: 100px;
    width: 250px;
  }
  .brandsSection ul li img {
    background: inherit;
    /* opacity: 0.5; */
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}
