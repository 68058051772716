.aboutContainer {
  background-color: #000;
  color: #fff;
  padding: 100px 20px;
}
.aboutContainer header {
  margin-bottom: 40px;
}
.aboutContainer h2 {
  font-size: 40px;
  text-align: center;
  margin-bottom: 20px;
}
.aboutContainer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}
.aboutContainer ul img {
  width: 300px;
  height: 400px;
}

.aboutContainer ul li figure {
  position: relative;
  /* cursor: pointer; */
}
.aboutContainer ul li figure figcaption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(48, 48, 46, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #e4b848;
  opacity: 0; /* Start with 0 opacity */
  transition: opacity 0.5s ease-in-out; /* Add a transition for opacity */
  pointer-events: none; /* Disable pointer events to prevent interactions when hidden */
}
.aboutContainer ul li figure figcaption p {
  border: 1px solid #e4b848;
  padding: 10px 16px;
}
.aboutContainer ul li figure:hover figcaption {
  opacity: 1; /* On hover, make the figcaption visible */
  pointer-events: auto; /* Re-enable pointer events when visible */
}

.artistDetailsContainer {
  padding: 120px 20px;
  background: #000;
  color: #fff;
  height: auto;
}
.artistDetailsContainer header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.artistDetailsContainer header img {
  width: 100%;
  height: 500px;
}

.artistDetailsContainer header h2 {
  text-align: center;
  padding: 0 0 40px 0;
  font-size: 30px;
}
.artistDetailsContainer article p {
  padding: 40px 0;
  line-height: 30px;
}
.artistDetailsContainer ul {
  display: flex;
  align-items: center;
  gap: 20px;
  /* justify-content: center; */
  font-size: 26px;
  margin: 10px 0;
}
.artistDetailsContainer .latestSingle img {
  width: 100%;
  height: 400px;
  margin: 20px 0 0 0;
}
.artistDetailsContainer .latestSingle button {
  margin: 20px 0 40px 0;
  background: inherit;
  height: 38px;
  padding: 0 20px;
  font-size: 16px;
  color: #e4b848;
  border: 1px solid #e4b848;
  border-radius: 2px;
  min-width: 130px;
  justify-content: center;
  gap: 10px;
  font-family: "Mooli", sans-serif;
}
.backBtn {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0 20px 0;
  color: #e4b848;
}

@media screen and (min-width: 768px) {
  .aboutContainer {
    padding: 140px 200px;
    height: 100vh;
  }
  .aboutContainer h2 {
    font-size: 70px;
  }
  .aboutContainer ul {
    flex-wrap: nowrap;
  }
  .artistDetailsContainer {
    padding: 200px;
  }

  .artistDetailsContainer header h2 {
    font-size: 60px;
  }
  .artistDetailsContainer header img {
    width: 400px;
    height: 500px;
  }
  .artistDetailsContainer .latestSingle img {
    width: 400px;
    height: 400px;
  }
}
