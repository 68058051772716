/* background: linear-gradient(to right, rgba(237, 224, 190, 0.15), rgba(211, 179, 99, 0.15)) !important */
.contactContainer {
  background: #000;
  /* height: 100vh; */
  padding: 100px 20px;
  color: #fff;
}
.contactContainer header {
  text-align: center;
}
.contactContainer header h2 {
  font-size: 30px;
  line-height: 30px;
}

.contactContainer header p {
  max-width: 100%;
  line-height: 30px;
}
.contactContainer ul {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  gap: 40px;
  flex-wrap: wrap;
}

.contactContainer ul li a {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  color: #fff;
  text-align: center;
}

.contactContainer ul li div {
  height: 60px;
  width: 60px;
  border-radius: 99px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, rgba(237, 224, 190, 0.15), rgba(211, 179, 99, 0.15)) !important;
}
.contactContainer ul li div img {
  width: 40px;
  height: 40px;
}
.contactFormContainer {
  /* width: 100%; */
  background: #fff;
  border-radius: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-clip: border-box;

  -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}
.contactFormContainer input {
  border: none;
  background: #f8f9fa;
  padding: 16px;
  color: #495057;
  font-family: "Mooli", sans-serif;
}
.contactFormContainer textarea {
  border: none;
  background: #f8f9fa;
  padding: 16px;
  color: #495057;
  font-family: "Mooli", sans-serif;
  height: 150px;
}
.contactFormContainer button {
  background: linear-gradient(to right, #d6bb6f, #a2812e) !important;
  border: none;
  padding: 16px;
  font-family: "Mooli", sans-serif;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  border-radius: 6px;
}
::placeholder {
  color: #495057;
  font-family: "Mooli", sans-serif;
}

.careersContainer {
  background: #000;
  height: 100vh;
  padding: 40px 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.careersContainer header h2 {
  font-size: 30px;
  margin-bottom: 10px;
}
.careersContainer header p {
  margin-bottom: 5px;
}

@media screen and (min-width: 768px) {
  .contactContainer {
    padding: 200px;
  }
  .contactContainer ul {
    gap: 100px;
  }
  .contactContainer header h2 {
    font-size: 70px;
    line-height: 90px;
  }

  .contactContainer header p {
    max-width: 700px;
    margin: 0 auto;
  }
  .contactFormContainer {
    max-width: 500px;
    margin: 0 auto;
    padding: 40px;
  }
  .careersContainer header h2 {
    font-size: 70px;
  }
}
