.navContainer {
  padding: 12px 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
}

.navContainer img {
  width: 250px;
  height: 200px;
  position: absolute;
  top: -50px;
  left: -50px;
}
.navContainer ul {
  gap: 40px;
}

.navContainer button {
  background: inherit;
  border: none;
}
.mobileNav {
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background: linear-gradient(to bottom, rgba(48, 48, 46, 0.8) 0%, /* Adjust opacity here (0.8 in this case) */ rgba(77, 68, 52, 0.8) 18.3%, /* Adjust opacity here */ rgba(77, 68, 52, 0.8) 36.6%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 68.3%, /* Adjust opacity here */ rgba(105, 91, 54, 0.8) 100% /* Adjust opacity here */);
  z-index: 5px;
  padding: 20px;
}

.footerContainer {
  background: #000;
  padding: 40px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: #fff;
  font-family: "Mooli", sans-serif;
}
.footerContainer img {
  height: 100%;
  border: 1px solid #e4b848;
  width: 100%;
  object-fit: contain;
}
.footerContainer ul {
  justify-content: space-evenly;
  margin: 20px 0;
}
.flexColumn {
  gap: 20px;
}
.footerContainer ul li:first-child {
  color: #e4b848;
}
.mobileCallButton {
  position: fixed;
  right: 24px;
  top: 20px;
}

@media screen and (min-width: 768px) {
  .navContainer {
    padding: 24px 80px;
  }
  .navContainer li {
    /* margin: 0 44px 0 0; */
  }
  .navContainer img {
    width: 250px;
    height: 200px;
    top: -50px;
  }
  .footerContainer {
    padding: 100px 80px;
  }
  .footerContainer img {
    height: 200px;
    border: 1px solid #e4b848;
    width: 200px;
    object-fit: contain;
  }
  .footerContainer ul {
    margin: 0;
  }
}
