.flexRow {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}

.flexWrap {
  flex-wrap: wrap;
}
.noWrap {
  flex-wrap: nowrap;
}

.justifyBetween {
  justify-content: space-between;
}
.justifyEnd {
  justify-content: flex-end;
}

.justifyCenter {
  justify-content: center;
}
.justifySelfEnd {
  justify-self: flex-end;
}
.justifySelfCenter {
  justify-self: center;
  /* border: 1px solid red; */
}

.alignCenter {
  align-items: center;
}
.alignSelfCenter {
  align-self: center;
}

.alignSelfEnd {
  align-self: flex-end;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}
.slide-in-section {
  opacity: 0;
  transform: translateX(-20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 0.8s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
.slide-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}
