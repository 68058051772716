.blogSection {
  background-color: #000;
}
.blogSection header {
  height: 100px;
  padding-top: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.blogSection header h2 {
  font-size: 30px;
}
.largeBlogCard {
  border: 1px solid #e4b848;
  border-radius: 6px;
  padding: 16px;
  background: #000;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
}
.largeBlogCard figcaption h4 {
  margin: 10px 0;
}
.blogsContentContainer {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 0 20px;
  color: #fff;
}
.blogsContentContainer .largeBlogCard img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.blogsContentContainer figure {
  display: flex;
  gap: 20px;
}

.smallBlogCard {
  /* width: 100% !important; */
  border: 1px solid #e4b848;
  border-radius: 6px;
  padding: 16px;
  background: #000;
  margin: 20px 0;
}
.blogsContentContainer .smallBlogCard img {
  width: 150px;
  height: 150px;
  border-radius: 12px;
  object-fit: cover;
}
.smallCardTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  font-size: 12px;
}
.bgHolder {
  padding: 5px 10px;
  background: #e4b848;
  border-radius: 16px;
}
.smallBlogCard h4 {
  font-size: 14px;
  max-width: 300px;
  padding: 10px 0;
  line-height: 22px;
}
.mBackground {
  border-radius: 99px;
  padding: 5px;
  background: #e4b848;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smallCardBtm {
  display: flex;
  gap: 20px;
}
.smallCardBtm div:nth-child(2) p {
  font-size: 12px;
  margin: 0 0 5px 0;
}

@media screen and (min-width: 768px) {
  .blogSection header {
    height: 400px;
  }
  .blogSection header h2 {
    font-size: 70px;
  }
  .blogsContentContainer {
    flex-wrap: nowrap;
    gap: 20px;
    padding: 0 200px;
  }
  /* .largeBlogCard {
  border: 1px solid #e4b848;
  border-radius: 6px;
  padding: 16px;
  background: #000;
  display: flex;
  flex-direction: column;
  width: 100%;
} */

  .blogsContentContainer .largeBlogCard img {
    width: 400px;
    height: 400px;
  }
  /* .smallBlogCard {
  width: 100%;
  border: 1px solid #e4b848;
  border-radius: 6px;
  padding: 16px;
  background: #000;
  margin: 20px 0;
} */
  .blogsContentContainer .smallBlogCard img {
    width: 150px;
    height: 150px;
    border-radius: 12px;
  }
}
